<template>
    <section class="py-5 light-green-bg">
      <div class="container">
        <div class="row">
          <div class="col-md-3 pl-md-1">
            <div class="days-time">
              <!-- Content for days-time -->
            </div>
          </div>
          <div class="col-md-9">
            <div class="theme-cols">
              <div class="orange-text">Recommended for you</div>
              <div>
                <small>See All</small>
              </div>
            </div>
			
            <div class="recommended-businesses owl-carousel owl-them mt-3">				
				<BusinessCard v-for="(business, index) in recommendedBusinesses" :key="index" :business="business" />
				
				<!-- 
				<div class="business-card">
					<div class="business-card-cover" :style="{backgroundImage: `url(${business.coverPhotoUrl})`,backgroundPosition:'center'}">
						<div class="business-avatar">
							<img :src="business.avatarUrl" :alt="business.name" />
						</div>
					</div>

					<div class="business-info">
						<h5>{{ business.name }}</h5>
						<div class="business-ratings">
							<div>{{ business.rating }}</div>
							<div class="business-stars">
								<img v-for="(star, index) in business.stars" :src="star" :alt="`Star ${index + 1}`" :key="index" />
							</div>
						</div>
					</div>

					<div class="distance-info">
						<div>
							<img src="/images/map-marker.svg" alt="Map Marker Icon" />
						</div>
						<div class="business-kms">
							{{ business.distance }} km
						</div>
					</div>

					<div class="button-wrapper">
						<a href="#" class="book-now-btn">Book Now</a>
					</div>
				</div>
				-->
				
			
			</div>
			
			
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import BusinessCard from './businessCard.vue';
  import { HOME_RECOMMENDEDS } from "@/utils/requestOperation/query";
  import { getDistance, orderByDistance } from 'geolib';
  export default {
    name: 'RecommendedBusiness',
    components: {
		BusinessCard
    },
	props:[
		"latitude",
		"longitude"
	],
    data() {
		return {
			recommendedBusinesses: []
		};
    },
	async mounted(){
		let recommendedTake 	= 10;
		let recommendedSkip 	= 0;
		const { data: responseData, error: responseError } = await this.$apollo.query({
			query: HOME_RECOMMENDEDS,
			variables: { 
				recommendedTake,
				recommendedSkip
			},
		});	
		
		if ( responseData ) {			
			
			let recommendedBusinesses	= responseData.getRecommendedBusinesses.map( ( business ) => {
				let newBusinessObject = { ...business };
				newBusinessObject.avatarUrl = business.owner.avatarUrl === null ? '/images/user.jpg': business.owner.avatarUrl;
				
				const coordsData = business.location.includes( ";" ) ? business.location.split( ";" ) : business.location.split( "-" );
				
				if( coordsData[0] &&  coordsData[1] ){
					console.log( coordsData );
					let distance = 10;
					//	let distance = getDistance(
					//		{ latitude: this.latitude, longitude: this.longitude },
					//		{ latitude: parseFloat( coordsData[0] ), longitude: parseFloat( coordsData[1] ) }
					//	);
					//	console.log( newBusinessObject );
					newBusinessObject = { ...newBusinessObject, distance }
					return newBusinessObject;
				}
			} );	
			
			this.recommendedBusinesses 	= recommendedBusinesses.filter(  ( business ) => business );
			console.log( this.recommendedBusinesses[0] );
			
		}else{
			console.log( responseError );
		}
		
		$( '.recommended-businesses' ).owlCarousel({
			stagePadding: 35,
			loop:true,
			margin:10,
			responsive:{
				0:{
					items:1.6
				},
				600:{
					items:3
				},
				1000:{
					items:3.8
				}
			}
		});
		
	},
  };
  </script>
  
  <style scoped>
  /* Add scoped styles as needed */
  .recommended-businesses{
	display: flex;
  }
  
  
  
  </style>
  