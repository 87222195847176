<template>
    <header id="header">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <nav id="header-menu">
              <div id="header-menu-logo-wrapper">
                <RouterLink to="/">
                  <img src="/images/logo.svg" id="header-logo" alt="Logo" />
                </RouterLink>

               
              </div>
              <div id="header-menu-search">
                <input type="search" placeholder="Search ..." />
              </div>
  
              <div class="header-widget-col">
                <img src="/images/video-camera.svg" alt="Video" />
                <!-- <span>2</span> -->
              </div>
  
              <div class="header-widget-col">
                <img src="/images/bell-alt.svg" alt="Notifications" />
                <span>2</span>
              </div>
  
              <div class="header-widget-col">
                <img src="/images/chat-round-dots.svg" alt="Messages" />
                <span>7</span>
              </div>
  
              <div class="header-widget-col">
                <img src="/images/shopping-cart.svg" alt="Shopping Cart" />
                <span>0</span>
              </div>
  
              <div id="header-menu-user">
                <div class="profile-stub">
                  <img src="/images/user.jpg" alt="User Avatar" />
                  <div>
                    <span>{{ user ? user?.firstname : "sixtus" }} {{ user ? user?.lastname :"donald" }}</span>
                    <span>Lagos, Nigeria</span>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div id="header-hanging-menu" class="white-bg header-hanging-menu">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="header-sub-menu-cols">
						<div class="active">Home</div>
						<span></span>
						<div>Bookings</div>
						<span></span>
						<div>Shops</div>
						<span></span>
						<div>Glitz</div>
						<span></span>						
						<div>Rewards</div>
						<span></span>	
						<div>Favourites</div>
						<span></span>	
						<div>Payments</div>	
						<!-- 
						<div>Personal Information</div>	
						 -->
					</div>
				</div>
			</div>
		</div>
	</div>
    </header>
  
  </template>
  
  <script>
  import Cookies from "js-cookie"
  import { RouterLink } from 'vue-router'
  import {CheckCookieExists} from "@/utils/helper"
  export default {
    name: 'NavHeader',
    components:{
      RouterLink
    }, 
    data(){
      return{
        user: Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null,
      }
    },
   
    created() {
    // this.isAuthenticated = CheckCookieExists("token");
    // if (this.isAuthenticated) {
    //   this.$router.push("/");
    // }
  },
  };
  </script>
  
  <style scoped>
  /* Add scoped styles as needed */

  </style>
  