<template>
  <section
    style="min-height: 100vh; background-color: #5ea180;"
    class="white- pt-3"
  >
    <div class="container">
      <div class="row justify-content-center my-5">
        <div class="col-md-6">
          <div class="user-form-wrapper">
            <div>
              <a href="/">
                <img src="@/assets/images/logo.png" style="width: 120px;" />
              </a>
            </div>
          </div>
          <div class="user-form-wrapper">
            <div class="mb-4">
              <h5>Welcome back</h5>
            </div>
            <form>
              <small>Email:</small>
              <div class="form-group">
                <input
                  v-model="email"
                  type="email"
                  required
                  class="form-control"
                  placeholder="Email Address"
                />
              </div>
              <small>Password:</small>
              <div class="form-group">
                <input
                  v-model="password"
                  type="password"
                  required
                  class="form-control"
                  placeholder="Password"
                />
              </div>
            </form>
          </div>
          <div class="user-form-wrapper">
            <div class="flex-cols">
              <div style="display: flex; align-items: center;">
                <input id="remember-me" type="checkbox" />
                <label
                  style="margin-bottom: 0; padding-left: 5px;"
                  class="tiny-fonts"
                  for="remember-me"
                  >Remember me</label
                >
              </div>
              <div>
                <a class="tiny-fonts" href="#">Forgot password?</a>
              </div>
            </div>
            <div class="mt-2">
              <button
                @click="handleSubmit"
                type="button"
                class="horizontal-btns"
              >
                {{ processing ? "processing..." : "Login" }}
              </button>
            </div>
            <div class="my-2">
              <span class="tiny-fonts">Don't have an account?</span>
              <a class="tiny-fonts" href="#"> Signup</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Cookies from "js-cookie";
import { LOGIN } from "@/utils/requestOperation/mutation";
import { CheckCookieExists} from "../utils/helper";

export default {
  name: "Login",
  data() {
    return {
      show: false,
      email: "",
      password: "",
      isAuthenticated: false,
      passwordError: false,
      processing: false,
    };
  },
  
  methods: {
    async handleSubmit() {

      if (this.email !== "" && this.password !== "") {
        this.processing = true;
        (async () => {
          try {
            const variable = {
              email: this.email,
              password: this.password,
            };

            const { data, errors } = await this.$apollo.mutate({
				mutation: LOGIN,
				variables:variable,
            });
            if (errors && errors.length > 0) {
				let error = errors.map(err => err.message).join(', ');
				this.$toast.error(error)
				console.log(errors.message,"kkk");
				this.processing = false;
				
            } else if( data?.login.user.role_id !== "ADMIN" ) {
				throw new Error( "Only admin members allowed" );
				
            } else {
				this.processing = false;
				console.log(data, "grrrrrr");
				Cookies.set(
					"user",
					JSON.stringify(data?.login.user),
					{ expires: 1 / 24 }
				);
				Cookies.set(
					"access-token",
					JSON.stringify(data?.login.token),
					{ expires: 1 / 24 }
				);
				this.processing = false;
				this.isAuthenticated = true;
				// window.location.href="/"
				this.$toast.success("Successful")
                this.$router.push("/");
            }
          } catch (e) {
            this.processing = false;
            this.$toast.error(e.message)
            console.log(e.message);
          }
        })();
      }
    },
  },
  async created() {
    this.isAuthenticated = CheckCookieExists("token");
    if (this.isAuthenticated) {
      window.location.href = "/";
    }
  },
};
</script>

<style scoped>
a {
  color: #4a8863;
}
.form-control {
  outline-color: #0c8d66 !important;
  padding: 10px 14px !important;
  border: 1px solid #eee !important;
  height: 45px;
}
</style>
