$(document).ready(function() {		
	
	if( document.body.contains( document.getElementById('header') ) ){
		window.addEventListener('scroll', function() {
			const header 			= document.getElementById('header');
			const headerHangingMenu = document.getElementById('header-hanging-menu');
			if (window.scrollY > 100) {
				header.classList.add('fixed');
			} else {
				header.classList.remove('fixed');
			}
			
			if ( window.scrollY > 350) {
				headerHangingMenu.classList.add('active');
			} else {
				headerHangingMenu.classList.remove('active');
			}			
		});	
	}
	
	
});
		
