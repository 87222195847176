<template>
    <div v-for="(product, index) in latestProducts" :key="index" class="latest-product-card">
      <div class="latest-product-cover" ></div>
      <div class="latest-product-content">
        <div class="product-name">{{ product.name }}</div>
        <div class="product-price">
          <div class="new-price">{{ product.newPrice }}</div>
          <div class="old-price">{{ product.oldPrice }}</div>
        </div>
        <div class="product-button-wrapper">
          <a href="#" class="book-now-btn">Shop Now</a>
        </div>
        <hr>
        <div class="owner-wrapper">
          <div class="owner-name">
            Owner: <span>{{ product.owner }}</span>
          </div>
          <div class="business-ratings">
            <div>{{ product.rating }}</div>
            <div class="business-stars">
              <img v-for="star in product.stars" :key="star" :src="star" />
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
  
  <script>
  export default {
    name: 'LatestProductCard',
    props: {
      product: {
        type: Object,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add scoped styles as needed */
  </style>
  