<template>
    <section class="py-5 light-green-bg">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="theme-cols">
              <div class="orange-text">Bookmi Specials</div>
              <div>
                <small>See All</small>
              </div>
            </div>
					
			
			<div class="bookmi-specials owl-carousel owl-theme mt-3">
				
				<div class="business-card" v-for="business in data"	>
					<div class="business-card-cover" :style="{ backgroundImage: `url(${business.coverPhotoUrl})`,backgroundPosition:'center' }">
						<div class="business-avatar">
							<img v-if="business.isFavorite" src="/images/heart-green-filled.svg" />
							<img v-if="!business.isFavorite" src="/images/heart-outline.svg" />
						</div>
					</div>
					
					<div class="business-info">
						<h5 class="business-name">{{ business.name }}</h5>
						<div class="business-ratings">
							<div v-if="business.ratingWholeNumber > 0">{{ business.ratingWholeNumber }}</div>
							<div class="business-stars">
								<img v-for="n in business.ratingWholeNumber" src="/images/star-sharp.svg" />
								<img v-if="business.ratingHasRemainder" src="/images/star-sharp-half.svg" />
								
							
							</div>
						</div>
					</div>
					<div class="distance-info">
						<div>
							<img src="/images/map-marker.svg" />
						</div>
						<div class="business-kms">
							{{business.distance}}
						</div>
					</div>
					
					<div class="button-wrapper">
						<a href="#" class="book-now-btn">Book Now</a>
					</div>			
					
				</div>
					
					
            </div>
			
			
			
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  
    export default {
		name: 'BookmiSpecials',
		props:[
			"data"
		],
		data() {
			return {
				
			};		
		},
		mounted(){
			
			$('.bookmi-specials').owlCarousel({
				loop:true,
				margin:10,
				rewind:true,
				autoplay:true,
				nav:false,
				dots:false,
				responsive:{
					0:{
						items:1.5
					},
					600:{
						items:3
					},
					1000:{
						items:6
					}
				}
			});
		}
	};
	
  </script>
  
  <style scoped>
	/* Add scoped styles as needed */
	.business-name{
		display: block;
		width: 100%;
		min-height: 56px !important;
	}
	
	.business-ratings{
		min-height: 21px !important;
		border: 0px solid #f00;
	}
  </style>
  