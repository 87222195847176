<template>
	<nav class="mobile-header">
		<div class="mobile-user-menu">
			<div class="header-menu-user">
				<div class="mobile-profile-stub">
					<img src="/images/user.jpg" />
					<div class="mobile-header-user-info">
						<span>Yemi Cardoso</span>
						<span>Lagos, Nigeria</span>
					</div>
				</div>
			</div>
			
			<div class="mobile-user-header-widget-col">
				<img src="/images/bell-alt.svg" />
				<span>2</span>	
			</div>
		
		</div>
	</nav>

	<section class="mobile-search">
		<div class="mobile-home-menu-search">
			<input type="search form-control" placeholder="Search ..." />						
		</div>
	</section>

</template>

<script>
import Cookies from "js-cookie"
import { RouterLink } from 'vue-router'
import {CheckCookieExists} from "@/utils/helper"
export default {
	name: 'MobileHeader',
	components:{
		RouterLink
	}, 
	data(){
		return{
		user: Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null,
		}
	}, 
	created() {
		
	},
};
  </script>
  
  <style scoped>
  /* Add scoped styles as needed */
	
  </style>
  