<template>
		
<section class="py-5 light-green-bg">
	<div class="container">
		<div class="row">			
			
			<div class="col-md-3 pl-md-1">
				<div class="days-time" style="height:355px">
				</div>
			</div>	
				
			<div class="col-md-9">
				<div class="theme-cols">
					<div class="orange-text">Recommended for you</div>
					<div>
						<small>See All</small>
					</div>
				</div>
				<div class="recommended-businesses owl-carousel owl-them mt-3">
					
					
					
					<div class="business-card slider-item" v-for="( business, index ) in recommendedBusinesses" :key="index">
					
					<!--
					<div class="business-card slider-item">
					-->	
						<div>
							<div class="business-card-cover" :style="{backgroundImage: `url( ${business.coverPhotoUrl} )`, backgroundPosition: 'center' }" >
								<div class="business-avatar">
									<img v-if="business.isFavourite" src="/images/heart-green-filled.svg" />
									<img v-if="!business.isFavourite" src="/images/heart-outline.svg" />
								</div>
							</div>
							
							<div class="business-info">
								<h5 class="business-name">{{business.name}}</h5>
								<div v-if="business.rating > 0" class="business-ratings">
									<div>{{business.rating}}</div>
									<div class="business-stars">
										<img v-for="n in business.rating" src="/images/star-sharp.svg" />										
									</div>
								</div>
							</div>
							<div class="distance-info">
								<div>
									<img src="/images/map-marker.svg" />
								</div>
								<div class="business-kms">
									{{business.distance}} Km.
								</div>
							</div>
							
							<div class="button-wrapper">
								<a href="#" class="book-now-btn">Book Now</a>
							</div>
						
						</div>
					</div>
					
					
					
					
					
					
					
					
					
				</div>
			</div>

		</div>
	</div>
	
	
	
</section>
</template>
<script>

import { HOME_RECOMMENDEDS } from "@/utils/requestOperation/query";
import { getDistance, orderByDistance } from 'geolib';
export default {
    name: 'RecommendedBusinesses',
    components: {
		
    },
	props:[
		"latitude",
		"longitude",
		"favoriteBusinesses"
	],
    data() {
		return {
			recommendedBusinesses: [],
			entries: []
		};
    },
	async mounted(){
		
		console.log( "++++++++++++++++ Favourite Business IDs  Starts" );
		console.log( this.favoriteBusinesses );
		console.log( "++++++++++++++++ Favourite Business IDs Ends" );
		
		let recommendedTake 	= 10;
		let recommendedSkip 	= 0;
		const { data: responseData, error: responseError } = await this.$apollo.query({
			query: HOME_RECOMMENDEDS,
			variables: { 
				recommendedTake,
				recommendedSkip
			},
		});	
		
		if ( responseData ) {			
			
			let recommendedBusinesses		= responseData.getRecommendedBusinesses.map( ( business ) => {
				let isFavourite  			= false;
				
				if( this.favoriteBusinesses.includes( business.id ) ){
					isFavourite  			= true;
				}
				
				let rating = 0;
				if( business.reviews.rating ){
					rating =getAverageRating( business.reviews.rating );
				}				
				
				let newBusinessObject 		= { ...business, isFavourite, rating };
				newBusinessObject.avatarUrl = business.owner.avatarUrl === null ? '/images/user.jpg': business.owner.avatarUrl;				
				const coordsData 			= business.location.includes( ";" ) ? business.location.split( ";" ) : business.location.split( "-" );			
				
				console.log( coordsData );
				
				if( coordsData[0] &&  coordsData[1] ){
					console.log( coordsData );
					let distance = 10;
					
					//	let distance = getDistance(
					//		{ latitude: this.latitude, longitude: this.longitude },
					//		{ latitude: parseFloat( coordsData[0] ), longitude: parseFloat( coordsData[1] ) }
					//	);
					//	console.log( newBusinessObject );
					
					newBusinessObject = { ...newBusinessObject, distance }
					return newBusinessObject;
					
				}
				
			} );	
			
			this.recommendedBusinesses 	= recommendedBusinesses.filter(  ( business ) => business ).slice(0, 20);
			console.log( "----------------" );
			console.log( this.recommendedBusinesses );
			
		}else{
			console.log( responseError );
		}
		
		
		$(document).ready(function(){
			$('.recommended-businesses').owlCarousel({				
				stagePadding: 35,
				loop:true,
				autoplay:true,
				margin:10,
				responsive:{
					0:{
						items:1.3
					},
					600:{
						items:3
					},
					1000:{
						items:3.5
					}
				}
			});
		});
		
	},
	methods: {
		getAverageRating( ratings = [] ){
			const initialValue = 0;
			rating = ratings.reduce( ( accumulator, currentValue ) => accumulator + currentValue,  initialValue, );			
		}
	}
  };
</script>

<style>
.business-name{
	display: block;
	min-height:49px;
}
</style>