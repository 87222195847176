<template>
  <section class="white-bg pt-3 pb-2">
    <div class="container">
      <div class="row">        
        <div class="col-md-9 pr-md-1">
			<div class="theme-cols">
				<div>
					Recent Glitzs
				</div>
				<div class="hidden-small">Trending</div>
				<div class="hidden-small">Favourites</div>	
				<div>
					<small>See All</small>
				</div>
			</div>
	
			<div class="owl-glitz owl-carousel owl-theme my-3">
				<div class="home-glitz-thumb slider-item" v-for="(glitz, index) in data" :key="index" :style="{ backgroundImage: `url(https://d54kmbwg38vk8.cloudfront.net/glitz/${glitz.preamble_gif})`,backgroundPosition:'center' }">
					<div class="glitz-avatar-flex">
						<div>
							<img :src="glitz?.owner?.avatar ? glitz.owner.avatarUrl : require('@/assets/images/user6.jpg')" alt="Avatar" />
						</div>
						<div>
							{{ glitz?.business?.name }}
						</div>								
					</div>
				</div>			
			</div>
			
        </div>
		
        <div class="col-md-3 pl-md-1">
          <div class="days-time">
            <!-- Content for days-time column (if any) -->
          </div>
        </div>
		
      </div>
    </div>
  </section>
  <section class="white-bg pt-3 pb-0">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="home-menu-cols">
            <div class="active">Home</div>
            <div>Bookings</div>
            <div>Shops</div>
            <div>Glitz</div>
            <div>Rewards</div>
            <div>Favourites</div>
            <div>Payments</div>
            <!-- 
					<div>Personal Information</div>	
					 -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import star from "../../assets/images/star-sharp.svg";
import user1 from "../../assets/images/user.jpg";
import user3 from "../../assets/images/user3.jpg";
import user6 from "../../assets/images/user6.jpg";
import user7 from "../../assets/images/user7.jpg";

export default {
	name: "RecentGlitzs",
	props:[
		"data"
	],
	data() {
		return {
			
		};		
	},
	mounted() {
		$('.owl-glitz').owlCarousel({
			loop:true,
			margin:10,
			rewind:true,
			autoplay:true,
			nav:false,
			dots:false,
			responsive:{
				0:{
					items:3.5
				},
				600:{
					items:3
				},
				1000:{
					items:6
				}
			}
		});
	}
  
  
};
</script>

<style scoped>
/* Add scoped styles as needed */
	.glitz-avatar-flex{
		position:absolute;
		padding: 10px;
		top:0px;
		left:0px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap:7px;
	}

	.glitz-avatar-flex > div:first-child{
		width:28px;
	}

	.glitz-avatar-flex > div:first-child > img{
		width:100%;
		border-radius: 50%;
	}

	.glitz-avatar-flex > div:last-child{
		flex:1;
		color: #fff;
		font-size:12px;
		line-height:14px;
		font-weight: bold;	
	}

</style>
