import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '../views/Home.vue';
import Login from '../views/login.vue';
import { CheckCookieExists } from '@/utils/helper';


  


const routes = [
	{
		path: "/",
		name:"home",
		component: LandingPage,
		meta: {
			requiresAuth: true,
		   }
	},{
		path: "/auth/login",
		name: "Login",
		component: Login,
		meta: {
			hideNavbar: true,
		   }
	}
];

const router = createRouter({
	history: createWebHistory( process.env.BASE_URL ),
	routes
});

router.beforeEach((to, from, next) => {

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

	const isAuthenticated = CheckCookieExists('access-token');
	const isAuthRoute = to.path.startsWith('/auth');


    if(requiresAuth && isAuthenticated===false){
	
      next('/auth/login');
    } else if (isAuthRoute && isAuthenticated) {
		next('/'); 
	  }else {
      next();
    }

});


export default router;
