import Cookies from "js-cookie";


export function CheckCookieExists(cookieName) {
    const cookieValue = Cookies.get( cookieName ) ? JSON.parse( Cookies.get( cookieName ) ) : null;
    return cookieValue !== null;
}

export async function GetLocation() {
    return new Promise((resolve, reject) => {
      const success = (position) => {
        const location = {
          latitude: position.coords.latitude.toString(),
          longitude: position.coords.longitude.toString()
        };
        resolve(location);
      };
  
      const error = (err) => {
        console.log(err);
        reject(err);
      };
  
      navigator.geolocation.getCurrentPosition(success, error);
    });
  }