<template>	
	<div class="">		
		<LandingPage />
	</div>
</template>

<script>

import LandingPage from '../components/landing/index.vue';
export default {
	name: 'HomePage',
	components: {
		LandingPage
	},
	
}



</script>

<style>


@media screen and (max-width: 992px) {
  
}


</style>