import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';
import Cookies from 'js-cookie';

function getHeaders() {
  const headers = {};
  const token = Cookies.get("access-token") ? JSON.parse(Cookies.get("access-token")) : null;
   
  if (token) {
    headers[ "Authorization" ] = `Bearer ${token}`;
  }
  headers["Content-Type"] = "application/json";
  return headers;
}


const httpLink = new HttpLink({
	uri: 'https://graphql-test.bookmi.pro',
	fetch: async (uri,options) => {
		options.headers = getHeaders();
		//	let response = await fetch(uri, options);
		//	console.log( await response.json() );
		//	return response;
		return fetch(uri, options);
	},
});



const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
  defaultOptions: {
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },

});

export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});
