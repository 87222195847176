<template>
    <section class="white-bg pt-3 py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-9 pr-md-1">
            <div class="theme-cols">
              <div>Trending Glitzs</div>
              <div>
                <small>See All</small>
              </div>
            </div>
  
            <div class="trending-glitz owl-carousel owl-theme my-3">
				<!-- Carousel items for trending glitzs -->
				<div class="home-glitz-thumb slider-item" v-for="(glitz, index) in data" :key="index" :style="{ backgroundImage: `url(https://d54kmbwg38vk8.cloudfront.net/glitz/${glitz.preamble_gif})`,backgroundPosition:'center' }">
					<div class="glitz-avatar">
						<img :src="glitz.owner.avatarUrl" alt="Avatar" />
					</div>
				</div>
            </div>
          </div>
  
          <div class="col-md-3 pl-md-1">
            <div class="days-time">
              <!-- Content for days-time -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script> 
  export default {
    name: 'TrendingGlitz',
	props: [ "data" ],
    data() {
		return {
			trendingGlitzs: []      
		};
    },
    mounted(){
		$('.trending-glitz').owlCarousel({
			loop:true,
			margin:10,
			rewind:true,
			autoplay:true,
			nav:false,
			dots:false,
			responsive:{
				0:{
					items:3.5
				},
				600:{
					items:3
				},
				1000:{
					items:5.5
				}
			}
		});
   
	}
  
  }
  </script>
  
  <style scoped>
  /* Add scoped styles as needed */
  </style>
  