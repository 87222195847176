<template>
	<!-- BOOKMI SHOPS -->
	<section class="py-5 light-green-bg">
		<div class="container">
			<div class="row">			
				<div class="col-md-8">
					<div class="theme-cols">
						<div class="orange-tex">Bookmi Shops</div>
						<div>
							<small>See All</small>
						</div>
					</div>
					<div class="py-2"></div>
					
					<div class="latest-products owl-carousel owl-theme">
					
						<div class="latest-product-card" v-for="(pick, index) in latestProducts" :key="index">
							<div class="latest-product-cover" :style="{backgroundImage: `url(${pick.coverPhotoUrl})`,backgroundPosition:'center'}"></div>
							<div class="latest-product-content">							
								<div class="product-name">
									{{pick.shortened_name}}
								</div>
								<div class="product-price">
									<div class="new-price">
										&#x20A6; {{pick.new_price}}
									</div>
									<div v-if="pick.discount > 0" class="old-price">
										&#x20A6; {{pick.old_price}}
									</div>
								</div>
								<div class="product-button-wrapper">
									<a href="#" class="book-now-btn">Shop Now</a>
								</div>
								
								<hr>
								<div class="owner-wrapper">
									<div class="owner-name">
										Owner: 
										<span> {{pick.business_name}} </span>
									</div>						
									<div v-if="pick.rating > 0" class="business-ratings">
										<div>{{pick.rating}}</div>
										<div class="business-stars">
											<img v-for="n in pick.rating" src="/images/star-sharp.svg" />											
										</div>
									</div>							
								</div>						
							</div>					
						</div>
					
					</div>
					
				</div>
				
				
				<div class="col-md-4 hidden-small">
					<div class="theme-cols">
						<div class="orange-tex">Top Picks</div>
						<div>
							<small>See All</small>
						</div>
					</div>
					<div class="py-2"></div>
					
					<div class="top-products" v-for="( pick, index ) in topPicks" :key="index">
						<div class="top-product-thumb" :style="{backgroundImage: `url(${pick.coverPhotoUrl})`,backgroundPosition:'center'}"></div>
						<div class="top-product-title">
							<strong class="top-product-name">{{pick.shortened_name}}</strong>
							<div class="top-pick-product-price">
								<div class="new-price">{{pick.new_price}}</div>
								<div v-if="pick.discount > 0" class="old-price">{{pick.old_price}}</div>
							</div>
							
							<div class="top-pick-owner-wrapper">
								<div class="top-pick-owner-name">
									Owner: <span>{{pick.business_name}}</span>
								</div>						
								<div v-if="pick.rating > 0" class="top-pick-business-ratings">
									<div>{{pick.rating}}</div>
									<div class="business-stars">
										<img v-for="n in pick.rating" src="/images/star-sharp.svg" />											
									</div>
								</div>							
							</div>					
						</div>					
					</div>
					
					
						

					
				</div>
				
			</div>
		</div>
	</section>

</template>

<script>
import LatestProductCard from "./latestProductCard.vue"; // Ensure correct path s
import { HOME_SHOPS } from "@/utils/requestOperation/query";	

export default {
	name: "LatestProducts",
	components: {
		LatestProductCard,
	},
	data() {
		return {
			latestProducts: [ 1,2,3,4,5,6,7,8,9,10 ],
			topPicks: [ 1,2,3,4 ],
		};
	},
	async mounted(){
		let allProductTake 	= 10;
		let topPickTake 	= 4;
		const { data: responseData, error: responseError } = await this.$apollo.query({
			query: HOME_SHOPS,
			variables: { 
				allProductTake,
				topPickTake
			},
		});	
		
		if ( responseData ) {			
			
			let allProducts 		= responseData.allProducts.map( ( product ) => {
				let shortened_name 	= this.shortenTexts( product.name, 70 );
				let old_price 		= this.number_format( product.price,2 );
				let discount 		= ( product.price * product.discount ) / 100;
				
				if( discount && product.business.general_discount > 0 ){
					discount 		= ( product.price * product.business.general_discount ) / 100;
				}
				
				let new_price 		= product.discount === 0 ? this.number_format( product.price, 2 ) : this.number_format( ( product.price - discount ),2 );
				let business_name  	= product.business.name;
				
				let rating  		= 0;
				if(  product.business.reviews.rating ){
					rating  		= this.getAverageRating( product.business.reviews.rating );	
				}							
								
				return { ...product, shortened_name, old_price, new_price, business_name, rating };				
			
			} );
			
			let topPickProducts 	= responseData.topPickProducts.map( ( product ) => {
				
				let shortened_name 	= this.shortenTexts( product.name, 70 );
				let old_price 		= this.number_format( product.price,2 );
				let discount 		= ( product.price * product.discount ) / 100;
				
				if( discount && product.business.general_discount > 0 ){
					discount 		= ( product.price * product.business.general_discount ) / 100;
				}
				
				let new_price 		= product.discount === 0 ? this.number_format( product.price, 2 ) : this.number_format( ( product.price - discount ),2 );
				let business_name  	= product.business.name;
				let rating  		= 0;
				if(  product.business.reviews.rating ){
					rating  		= this.getAverageRating( product.business.reviews.rating );	
				}			
								
				return { ...product, shortened_name, old_price, new_price, business_name, rating };				
						
			} );
			
			this.latestProducts 	= allProducts.filter( ( product ) => product.new_price > 0  || product.new_price != "" );
			this.topPicks 			= topPickProducts.filter( ( product ) => product.new_price > 0  || product.new_price != "" );
			//	console.log( this.latestProducts );
			//	console.log( this.topPicks );
			
		}else{
			console.log( responseError );
		}
		
		$('.latest-products').owlCarousel({
			stagePadding: 35,
			loop:true,
			autoplay:true,
			margin:10,
			responsive:{
				0:{
					items:1.3
				},
				600:{
					items:3
				},
				1000:{
					items:3
				}
			}
		});
		
	},
	methods: {
		shortenTexts( text, count ){
			const textsCount = [...text].length;
			let desc = [...text].splice( 0, count );
			desc = desc.join( "" );
			return textsCount > count ? desc + " ..." : desc;
		},
		number_format( number, decimals, dec_point, thousands_sep ){
			var n = !isFinite(+number) ? 0 : +number, 
				prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
				sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
				dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
				toFixedFix = function (n, prec) {
					// Fix for IE parseFloat(0.55).toFixed(0) = 0;
					var k = Math.pow(10, prec);
					return Math.round(n * k) / k;
				},
				s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
			if (s[0].length > 3) {
				s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
			}
			if ((s[1] || '').length < prec) {
				s[1] = s[1] || '';
				s[1] += new Array(prec - s[1].length + 1).join('0');
			}
			return s.join(dec);
		},
		getAverageRating( ratings = [] ){
			const initialValue = 0;
			rating = ratings.reduce( ( accumulator, currentValue ) => accumulator + currentValue,  initialValue, );			
		}
	},
	
};
</script>

<style scoped>
/* Add scoped styles as needed */
</style>
