import gql from 'graphql-tag';
export const HOME_QUERY = gql`	
query ( $userId: ID!, $allGlitzTake: Int!, $recommendedBusinessesSkip: Int!, $recommendedBusinessesTake: Int! ) {
	user( id: $userId ){
		id
		firstname
		lastname
		email
		phone
		role_id
		likedGlitz {
			id
			glitz
			glitzLikesCount
			glitzLovers {
				id
				firstname
				avatar
			}
		}
		favouriteBusinesses{
			id
			business_id	
		}		
		favouriteBusinessesWithGlitz{
			id
			
		}
		
	}
	
	allGlitz( take: $allGlitzTake ) {
		description
		business_id
		id
		caption_text
		preamble_gif
		preamble
		business{
			name
		}
		owner {
			avatar
			avatarUrl
			firstname
			lastname
		}
	}
	
	trendingGlitz( take: $allGlitzTake ) {
		description
		business_id
		id
		caption_text
		preamble_gif
		preamble
		business{
			name
		}
		owner {
			avatar
			avatarUrl
			firstname
			lastname
		}
	}
	
	allCategories {
		icon
		name
	}
	
	allBookmiSpecialSubscriptions {
		id
		business {
		  id
		  name
		  location
		  reviews {
			rating
		  }
		  coverPhotoUrl
		  owner {
			avatar
			avatarUrl
		  }
		}
	}
	
	getRecommendedBusinesses(skip: $recommendedBusinessesSkip, take: $recommendedBusinessesTake ) {
		id
	}
	
	

}`

export const NEAR_ME_BUSINESSES = gql`
query ( $nearMeLongitude: String!, $nearMeLatitude: String!, $nearMeSkip: Int!, $nearMeTake: Int!  ){
	getNearMeBusinesses( longitude: $nearMeLongitude, latitude: $nearMeLatitude, skip: $nearMeSkip, take: $nearMeTake  ){
		id
		name
		location
		reviews {
			rating
		}
		coverPhotoUrl
		owner {
			avatar
			avatarUrl
		}
	}	
}`;

export const HOME_ARTICLES = gql`
query ( $topArticlesTake: Int, $activeArticlesTake: Int ){
	
	topArticles( take: $topArticlesTake ){
		id
		title
		slug
		cover_photo
		coverPhotoUrl
	}
	
	allActiveArticles( take: $activeArticlesTake ){
		id
		title
		slug
		cover_photo
		coverPhotoUrl
	}
		
}`;

export const HOME_PRODUCTS = gql`
query ( $topArticlesTake: Int, $activeArticlesTake: Int ){
	
	topArticles( take: $topArticlesTake ){
		id
		title
		slug
		cover_photo
		coverPhotoUrl
	}
	
	allActiveArticles( take: $activeArticlesTake ){
		id
		title
		slug
		cover_photo
		coverPhotoUrl
	}
		
}`;

export const HOME_SHOPS = gql`
query ( $allProductTake: Int, $topPickTake: Int ){
	
	allProducts( take: $allProductTake ){
		id
		name
		slug
		price
		discount
		coverPhotoUrl
		user {
		  id
		  firstname
		  lastname
		}		
		business {
		  name
		  id
		  general_discount
		  reviews {
			rating
		  }
		}
	}
		
	topPickProducts( take: $topPickTake ){
		id
		name
		slug
		price
		discount
		coverPhotoUrl
		user {
		  id
		  firstname
		  lastname
		}	
		business {
		  name
		  id
		  general_discount
		  reviews {
			rating
		  }
		}
	}
		
}`;

export const HOME_RECOMMENDEDS = gql`
query ( $recommendedTake: Int!, $recommendedSkip: Int! ){
	getRecommendedBusinesses( take: $recommendedTake, skip: $recommendedSkip){
		id
		name
		location
		reviews {
			rating
		}
		coverPhotoUrl
		owner {
			avatar
			avatarUrl
		}
		is_active
	}		
}`;
