<template>
  <section class="white-bg py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="theme-cols">
            <div class="orange-text">Top Trending Articles</div>
            <div>
              <small>See All</small>
            </div>
          </div>
          <div class="py-2"></div>
          <div class="top-articles"  v-for="( article, index) in topTrendingArticles" :key="index">
            <div class="top-article-thumb" :style="{backgroundImage: `url(${article.coverPhotoUrl})`,backgroundPosition:'center'}"></div>
            <div class="top-article-title">
              <strong>
                {{ article.shortened_title }}
              </strong>
            </div>
          </div>
        </div>

        <div class="col-md-8">
          <div class="theme-cols">
            <div class="orange-text">Latest Articles</div>
            <div>
              <small>See All</small>
            </div>
          </div>
          <div class="py-2"></div>
			
			<!--
			<div class="" v-for="(article, index) in latestArticles" :key="index">
				<div class="latest-article-cover"></div>
				<div class="latest-article-content">							
					{{article.shortened_title}}
				</div>
			</div>
			-->
			
          <div class="latest-articles owl-carousel owl-theme">
				<!-- Carousel items for latest articles -->            
				<div class="latest-article-card" v-for="(article, index) in latestArticles" :key="index">
					<div class="latest-article-cover" :style="{backgroundImage: `url(${article.coverPhotoUrl})`,backgroundPosition:'center'}"></div>
					<div class="latest-article-content">							
						{{article.shortened_title}}
					</div>
				</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HOME_ARTICLES } from "@/utils/requestOperation/query";	
export default {
	name: "TopTrendingArticles",
	data() {
		return {
			topTrendingArticles: [1,2,3],
			latestArticles: [1,2,3,4],
		};
	},
	async mounted(){
		let topArticlesTake 	= 3;
		let activeArticlesTake 	= 21;
		const { data: responseData, error: responseError } = await this.$apollo.query({
			query: HOME_ARTICLES,
			variables: { 
				topArticlesTake,
				activeArticlesTake
			},
		});	
		
		if ( responseData ) {			
			
			let topTrendingArticles 	= responseData.topArticles.map( ( article ) => {
				let shortened_title = this.shortenTexts( article.title, 70 );
				let newArticle 		= { ...article, shortened_title };
				return newArticle;
			} );
			
			let latestArticles 	= responseData.allActiveArticles.map( ( article ) => {
				let shortened_title = this.shortenTexts( article.title, 70 );
				let newArticle 		= { ...article, shortened_title };
				return newArticle;
			} );
			
			this.topTrendingArticles 	= topTrendingArticles;
			this.latestArticles 		= latestArticles;
			//	console.log( this.topTrendingArticles );
			//	console.log( this.latestArticles );
			
		}else{
			console.log( responseError );
		}
		
		$('.latest-articles').owlCarousel({
			stagePadding: 35,
			loop:true,
			margin:10,
			responsive:{
				0:{
					items:1.2
				},
				600:{
					items:3
				},
				1000:{
					items:3.3
				}
			}
		});
		
	},
	methods: {
		shortenTexts( text, count ){
			const textsCount = [...text].length;
			let desc = [...text].splice( 0, count );
			desc = desc.join( "" );
			return textsCount > count ? desc + " ..." : desc;
		}
	},
	
	
};
</script>

<style scoped>
/* Add scoped styles as needed */
</style>
