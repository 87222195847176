<template>
    <section class="white-bg pt-3">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="owl-cats owl-carousel owl-theme my-3">
               <div class="slider-item category-item" v-for="(category, index) in data" :key="index"> 
                <div>
                  <!-- <img :src="'https://d54kmbwg38vk8.cloudfront.net/home-service.svg'" /> -->
                  <img :src="'https://d54kmbwg38vk8.cloudfront.net/'+category.icon" />
                  <small>{{ category.name }}</small>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'Category',
    props:[
      "data"
    ],
    data() {
      return {
        categories: [
          { slug: 'Lifestyle', icon: 'https://d54kmbwg38vk8.cloudfront.net/lifestyle.svg' },
          { slug: 'Fitness', icon: 'https://d54kmbwg38vk8.cloudfront.net/fitness.svg' },
          { slug: 'Health', icon: 'https://d54kmbwg38vk8.cloudfront.net/health-and-wellness.svg' },
          { slug: 'Home', icon: 'https://d54kmbwg38vk8.cloudfront.net/home-service.svg' },
          { slug: 'Barbershop', icon: 'https://d54kmbwg38vk8.cloudfront.net/babershop.svg' },
          { slug: 'Salon', icon: 'https://d54kmbwg38vk8.cloudfront.net/hair-salon.svg' },
          { slug: 'SPA', icon: 'https://d54kmbwg38vk8.cloudfront.net/spa.svg' },
          { slug: 'Tattoo', icon: 'https://d54kmbwg38vk8.cloudfront.net/piercing-tattoos.svg' }
        ]
      };
    },
	mounted(){
		$('.owl-cats').owlCarousel({
			loop:true,
			margin:10,
			rewind:true,
			autoplay:true,
			nav:false,
			dots:false,
			responsive:{
				0:{
					items:7
				},
				600:{
					items:14
				},
				1000:{
					items:21
				}
			}
		});
	
	}
  };
  </script>
  
  <style scoped>
  /* Add scoped styles as needed */
  </style>
  