<template>
  <div class="id" style="position: relative; min-height: 100vh;">
    <NavBar v-if="!$route.meta.hideNavbar" />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import LandingPage from "../src/views/Home.vue";
import Login from "../src/views/login.vue";

export default {
  name: "App",
  components: {
    LandingPage,
    Login,
  },
  setup() {
    console.log("Bookmi Web 1.0!");
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style>
@import url("./assets/css/style.css");
@import url("./assets/css/bootstrap.min.css");
@import url("./assets/vendors/owlcarousel/assets/owl.carousel.css");
@import "@fortawesome/fontawesome-free/css/all.css";
@import "material-design-icons-iconfont/dist/material-design-icons.css";
.owl-nav {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.owl-nav > button {
  display: inline-block;
  width: 35px;
  height: 35px;
  background-color: green !important;
}

.owl-nav {
  display: none !important;
}

.category-item,
.category-item > img {
  border-radius: 50%;
}

.category-item small {
  display: inline-block;
  width: 100%;
  font-size: 8px;
  text-align: center;
  font-weight: bold;
}

html {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
}
body {
  margin: 0;
  min-height: 100vh;
}

.v-toast.v-toast--top > .alert{
	padding:3px !important;
}
</style>
