import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@fortawesome/fontawesome-free/css/all.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "./assets/js/script.js";
import Footer from "./components/footer.vue";
import NavBar from "./components/landing/nav.vue";
import { apolloProvider } from "./utils/apollo-client";
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import Preloader from "./components/preloader.vue";
import.meta.env

const app = createApp(App);
app.use(router);
app.component("Footer", Footer).component("NavBar", NavBar).component( "PreLoader", Preloader );

window.$ = window.jQuery = jQuery;

app.use(apolloProvider);
app.use( ToastPlugin, {
    position: 'top-right',
    dismissible:true,
    pauseOnHover:true
});

app.mount("#app");
