<template>
	<div class="business-card slider-ite">
		<div class="business-card-cover" :style="{backgroundImage: `url(${business.coverPhotoUrl})`,backgroundPosition:'center'}">
			<div class="business-avatar">
				<img :src="business.avatarUrl" :alt="business.name" />
			</div>
		</div>

		<div class="business-info">
			<h5>{{ business.name }}</h5>
			<div class="business-ratings">
				<div>{{ business.rating }}</div>
				<div class="business-stars">
					<img v-for="(star, index) in business.stars" :src="star" :alt="`Star ${index + 1}`" :key="index" />
				</div>
			</div>
		</div>

		<div class="distance-info">
			<div>
				<img src="/images/map-marker.svg" alt="Map Marker Icon" />
			</div>
			<div class="business-kms">
				{{ business.distance }} km
			</div>
		</div>

		<div class="button-wrapper">
			<a href="#" class="book-now-btn">Book Now</a>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    business: {
      type: Object,
      required: true
    }
  }
};
</script>

<!-- <style scoped>
.business-card {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.business-avatar img {
  width: 100%;
  height: auto;
}

.business-info {
  flex: 1;
  padding: 0 10px;
}

.business-ratings {
  display: flex;
  align-items: center;
}

.business-stars img {
  width: 20px; /* Adjust size as needed */
  margin-right: 5px;
}

.distance-info {
  display: flex;
  align-items: center;
}

.distance-info img {
  width: 20px; /* Adjust size as needed */
  margin-right: 5px;
}

.button-wrapper {
  padding-left: 10px;
}
</style> -->
