<template>
    <section class="py-5 white-bg">
      <div class="container">
        <div class="row">
          <div class="col-md-9">
            <div class="theme-cols">
              <div>Near You</div>
              <div>
                <small>See All</small>
              </div>
            </div>
            <div class="near-business owl-carousel owl-theme mt-4">
				
				<div class="business-card slide-item" v-for="business in data"	>  
					<div class="business-card-cover" :style="{ backgroundImage: `url(${business.coverPhotoUrl})`,backgroundPosition:'center' }">
						<div class="business-avatar">
							<img v-if="business.isFavorite" src="/images/heart-green-filled.svg" />
							<img v-if="!business.isFavorite" src="/images/heart-outline.svg" />
						</div>
					</div>
						
					<div class="business-info">
						<h6 class="business-name">{{ business.name }}</h6>
						<div class="business-ratings">
							<div v-if="business.ratingWholeNumber > 0">{{ business.ratingWholeNumber }}</div>
							<div class="business-stars">
								<img v-for="n in business.ratingWholeNumber" src="/images/star-sharp.svg" />
								<img v-if="business.ratingHasRemainder" src="/images/star-sharp-half.svg" />
								
							
							</div>
						</div>
					</div>
					<div class="distance-info">
						<div>
							<img src="/images/map-marker.svg" />
						</div>
						<div class="business-kms">
							{{business.distance}}
							<!-- 12 Km. -->
						</div>
						
						
					</div>
					
					<div class="button-wrapper">
						<a href="#" class="book-now-btn">Book Now</a>
					</div>
				</div>



            </div>
          </div>
  
          <div class="col-md-3 pl-md-1">
            <div class="days-time" style="height: 355px;">
              <!-- Placeholder for days-time content -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  

	<script>
		import { getDistance, orderByDistance } from 'geolib';
		export default {
			name: 'NearYouSection',
			props:[
				"data"
			],
			data() {
				return {
					nearBusinesses: []
				};
			},
			mounted(){
				
				
			}
		};
	</script>

  
  <style scoped>
  /* Add scoped styles as needed */
	.business-name{
		display: block;
		width: 100%;
		min-height: 42px !important;
	}
  </style>
  