<template>
	<!--
    <footer class="py-3 white-bg" style="top: auto;">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="footer-flex">
              <div>
                <small>&copy; 2024. <a href="#">Bookmi.pro</a> All Rights Reserved</small>
              </div>
              <div class="footer-legals footer-flex">
                <small><a href="#">Terms and Conditions</a></small>
                <span></span>
                <small><a href="#">Privacy Policy</a></small>
                <span></span>
                <small><a href="#">Refund Policy</a></small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
	-->
	<footer class="py-3 hidden-small">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="footer-cols">
					<div>
						<small>
							&copy;
							2024. <a href="#">Bookmi.pro</a> All Rights Reserved
						</small>
					</div>
					<div class="footer-legals">
					
						<small>
							<a href="#">Terms and Conditions</a> 
						</small>
						<span></span>
						<small>
							<a href="#">Privacy Policy</a> 
						</small>						
						<span></span>
						<small>
							<a href="#">Refund Policy</a> 
						</small>
						
					
					</div>
				</div>
				
			</div>
		</div>
	</div>
</footer>


<section class="mobile-bottom-nav">
	<div class="bottom-nav">
		<div>
			<div class="bottom-nav-icon">
				<img src="/images/bottom-nav/home.svg" style="width: 28px;" />
			</div>
			<span class="bottom-nav-label">
				Home
			</span>
		</div>
		<div>
			<div class="bottom-nav-icon">
				<img src="/images/bottom-nav/calender.svg" />
			</div>
			<span class="bottom-nav-label">
				Bookings
			</span>
		</div>
		<div>
			<div class="bottom-nav-icon">
				<img src="/images/bottom-nav/shopping.svg" />
			</div>
			<span class="bottom-nav-label">
				Shop
			</span>
		</div>
		<div>
			<div class="bottom-nav-icon">
				<img src="/images/bottom-nav/play.svg" style="width: 21px;" />
			</div>
			<span class="bottom-nav-label">
				Glitz
			</span>
		</div>
		<div>
			<div class="bottom-nav-icon">
				<img src="/images/bottom-nav/menu.svg" style="width: 18px;" />
			</div>
			<span class="bottom-nav-label">
				More
			</span>
		</div>
		
		
	</div>
</section>
  </template>
  
  <script>
  export default {
    name: 'AppFooter'
  };
  </script>
  
  <style scoped>
  footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* height: 2.5rem;     */
}
  .white-bg {
    background-color: #ffffff;
  }
  .footer-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
   a{
    color: #4A8863;
  }
  .footer-legals{
    width: 35%;
  }
  </style>
  