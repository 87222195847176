<template>
	
	<PreLoader v-if="isLoading"/>		
	<div id="container" v-if="!isLoading">
		<MobileHeader />
		<Categories :data="ALL_PRODUCT_CATEGORIES"/>
		<RecentGlitzs :data="allGlitz"/>
		<BookmiSpecials :data="bookmiSpecialSubscriptions" />
		<NearYouSection :data="nearMeBusinesses" />
		<RecommendedBusinesses :latitude="latitude" :longitude="latitude" :favoriteBusinesses="favoriteBusinessIds" />		
		<TrendingGlitz :data="trendingGlitz" />
		<LatestProducts />
		<TopTrendingArticles />
	</div>
	
</template>

<script>

	import Cookies from "js-cookie";
	import MobileHeader from "./MobileHeader.vue"
	import Categories from "./category.vue"
	import RecentGlitzs from "./recentGlitzs.vue"
	import BookmiSpecials from  "./bookmiSpecials.vue"
	import NearYouSection from "./nearYou.vue";
	import RecommendedBusiness from './recommendedBusiness.vue';
	import RecommendedBusinesses from './recommendedBusinesses.vue';
	import TrendingGlitz from './trendingGlitz.vue';
	import LatestProducts from './latestProducts.vue';
	import TopTrendingArticles from './topTrendingArticles.vue';
	import { HOME_QUERY, ACCOUNT_QUERY, NEAR_ME_BUSINESSES } from "@/utils/requestOperation/query";	
	import { getDistance, orderByDistance } from 'geolib';
	
	export default {
		name: 'Landing',
		components: {
			MobileHeader,
			Categories,
			RecentGlitzs,
			BookmiSpecials,
			NearYouSection,
			RecommendedBusinesses,
			RecommendedBusiness,
			TrendingGlitz,
			LatestProducts,
			TopTrendingArticles
		},		
		data(){
			return{
				myAccount : {},
				favoriteBusinessIds : [],
				likedGlitz : [],
				bookmiSpecialSubscriptions : [],
				favouriteBusinessesWithGlitz : [],
				nearMeBusinesses : [],
				allGlitz : [],
				trendingGlitz : [],
				ALL_PRODUCT_CATEGORIES: [],
				isLoading: true,
				latitude: null,
				longitude: null,
				error: null
			}
		},		
		async mounted() {			
			try {
				this.getLocation();
				let nearMeLongitude = this.longitude,  
				nearMeLatitude 		= this.latitude, 
				nearMeTake 			= 1000, 
				nearMeSkip 			= 0;			
				const cookies 		= JSON.parse( Cookies.get( "user" ) );			
				let userId 			= cookies.id;
				let allGlitzTake 	= 21;
				
				let recommendedBusinessesSkip = 0;
				let recommendedBusinessesTake = 21
				
				const { data: responseData, error: responseError } = await this.$apollo.query({
					query: HOME_QUERY,
					variables: { 
						userId, 
						allGlitzTake,
						recommendedBusinessesSkip,
						recommendedBusinessesTake
					},
				});			
				
				if ( responseData ) {					
					console.log( "\n++++++" );	
					console.log( responseData.allGlitz );
					let glitzEntries 			= responseData.allGlitz !== null ? [ ...responseData.allGlitz ] : [];	
						
					let trendingGlitz 			= responseData.trendingGlitz !== null ? [ ...responseData.trendingGlitz ] : [];					
					this.allGlitz 				= glitzEntries.filter( glitz => glitz.preamble_gif != null );
					this.trendingGlitz 			= trendingGlitz.filter( glitz => glitz.preamble_gif != null );
					
					console.log( "\n+++++ Trending Glitz" );
					console.log( this.trendingGlitz );					
					
					this.ALL_PRODUCT_CATEGORIES = responseData.allCategories;
					this.favoriteBusinessIds 	= responseData.user.favouriteBusinesses.map( ( { business_id } ) => business_id );
					
					this.favouriteBusinessesWithGlitz 		= responseData.user.favouriteBusinessesWithGlitz;
					const user 					= responseData.user;				
					let glitzs 					= responseData.user.likedGlitz.map( ( glitz ) => {
						glitz[ "isFavorite" ] 	= false;					
						if( this.favoriteBusinessIds.includes( glitz.business_id ) ){
							glitz[ "isFavorite" ] = true;
						}					
						return glitz;					
					}  );
					
					this.likedGlitz = glitzs;				
					this.myAccount 	= {
						firstname: user.firstname,
						lastname: user.lastname,
						email: user.email,
						roleId: user.role_id,
					};
					
					// AllBookmiSpecialSubscriptions | AllRunningBookmiSpecialSubscriptions
					
					let bookmiSpecialSubscriptions 		= responseData.allBookmiSpecialSubscriptions.map( ( { business } ) => {
						
						let isFavorite 				= false;
						let ratings 				= 0;					
						
						for( let review of business.reviews ){
							ratings += review.rating;
						}	
						
						const coordsData = business.location.includes( ";" ) ? business.location.split( ";" ) : business.location.split( "-" );
						let distance = getDistance(
							{ latitude: this.latitude, longitude: this.longitude },
							{ latitude: parseFloat( coordsData[0] ), longitude: parseFloat( coordsData[1] ) } // Example coordinates
						);
						
						distance = ( distance / 1000 ) + ' KM.';								
						
						let ratingWholeNumber 	= Math.floor( ratings );
						let ratingHasRemainder	= ratings % 1 > 0;
						
						if( this.favoriteBusinessIds.includes( business.business_id ) ){
							isFavorite = true;
						}
	
						let newBizObject = { ...business, isFavorite, ratingWholeNumber, ratingHasRemainder, distance };
						return newBizObject;
						
					}  );
					
					let orderedBookmiSpecialSubscriptions = orderByDistance( {
						latitude: parseFloat( this.latitude ), 
						longitude: parseFloat( this.longitude )
					}, bookmiSpecialSubscriptions );
					
					//	this.bookmiSpecialSubscriptions = orderedBookmiSpecialSubscriptions.slice( 0, 20 );
					
					this.bookmiSpecialSubscriptions = orderedBookmiSpecialSubscriptions.sort( ( a, b ) => {
						const calculateDistanceDifference = ( business ) => {
							//	if ( business.location === "" ) {
							//		return 0;
							//	}						
							return business.distance;
						};					
						return calculateDistanceDifference( b ) - calculateDistanceDifference( a );					
					} );
					
					this.isLoading 					= false;
	
					
				
				}else{
					console.log( responseError );
				}
				
			} catch (error) {
				console.log(error.message);
			}
		},
		
		watch: {
			latitude(newVal) {
				( async () => {
					await this.updateNearMeBusinesses();
					$('.near-business').owlCarousel({
						loop:true,
						margin:10,
						rewind:true,
						autoplay:true,
						nav:false,
						dots:false,
						responsive:{
							0:{
								items:1.5
							},
							600:{
								items:3
							},
							1000:{
								items:4.5
							}
						}
					});	
				})();
				
			},
			
			longitude(newVal) {
				( async () => {
					await this.updateNearMeBusinesses();
					$('.near-business').owlCarousel({
						loop:true,
						margin:10,
						rewind:true,
						autoplay:true,
						nav:false,
						dots:false,
						responsive:{
							0:{
								items:1.5
							},
							600:{
								items:3
							},
							1000:{
								items:4.3
							}
						}
					});
				})();
				
			},
			
			
			//	All_Glitz_() {
			//		this.$nextTick(() => {
			//			this.initializeCarousel();
			//		});
			//	}, 
			//	
			//	ALL_PRODUCT_CATEGORIES() {
			//		this.$nextTick(() => {
			//			this.initializeCarousel();
			//		});
			//	}
		},
			
		methods: {
			getLocation() {
			  if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(this.setPosition, this.showError);
			  } else {
				this.error = "Geolocation is not supported by this browser.";
			  }
			},
			setPosition(position) {
			  this.latitude = position.coords.latitude;
			  this.longitude = position.coords.longitude;			  
			},
			showError(error) {
			  switch(error.code) {
				case error.PERMISSION_DENIED:
				  this.error = "User denied the request for Geolocation.";
				  break;
				case error.POSITION_UNAVAILABLE:
				  this.error = "Location information is unavailable.";
				  break;
				case error.TIMEOUT:
				  this.error = "The request to get user location timed out.";
				  break;
				case error.UNKNOWN_ERROR:
				  this.error = "An unknown error occurred.";
				  break;
			  }
			},
			calculateDistance() {
			  if (this.latitude && this.longitude) {
				const distance = getDistance(
				  { latitude: this.latitude, longitude: this.longitude },
				  { latitude: 52.518611, longitude: 13.408056 } // Example coordinates
				);
				console.log(`Distance to Berlin: ${distance/1000} Kms`);
			  }
			},
			async updateNearMeBusinesses() {
							
				let nearMeLongitude = this.longitude !== null ? this.longitude.toString(): 3.3517634297477112;
				let nearMeLatitude 	= this.latitude !== null ? this.latitude.toString(): 6.601136809317097;
				let nearMeSkip		= 0;
				let nearMeTake		= 1000;	
			  
				const responseData = await this.$apollo.query({
					query: NEAR_ME_BUSINESSES,
					variables: { 
						nearMeLongitude,
						nearMeLatitude,
						nearMeSkip,
						nearMeTake
					},
				});
				
				if ( responseData ) {
				
					let nearMeBusinesses 	= [ ...responseData.data.getNearMeBusinesses ];
					nearMeBusinesses 	= nearMeBusinesses.map( ( business ) => {						
						
						let isFavorite 				= false;
						let ratings 				= 0;

						if( business.reviews.length > 0 ){
							for( let review of business.reviews ){
								ratings += review.rating;
							}
						}
						
						const coordsData = business.location.includes( ";" ) ? business.location.split( ";" ) : business.location.split( "-" );
						
						let distance = getDistance(
							{ latitude: this.latitude, longitude: this.longitude },
							{ latitude: parseFloat( coordsData[0] ), longitude: parseFloat( coordsData[1] ) } // Example coordinates
						);
						
						distance = ( distance / 1000 ) + ' KM.';
						
						let ratingWholeNumber 	= Math.floor( ratings );
						let ratingHasRemainder	= ratings % 1 > 0;
						
						if( this.favoriteBusinessIds.includes( business.id ) ){
							isFavorite = true;
						}
	
						let newBizObject = { ...business, isFavorite, ratingWholeNumber, ratingHasRemainder, distance };
						return newBizObject;
						
					}  );
					
					
					const orderedNearMeBusinesses = orderByDistance( {
						latitude: parseFloat( this.latitude ), 
						longitude: parseFloat( this.longitude )
					}, nearMeBusinesses );
					
					this.nearMeBusinesses = orderedNearMeBusinesses.slice( 0, 20 );
					
				}
				
				
			}
		},
			
		
			
		initializeCarousel() {
			this.destroyCarousel();			
			$('.owl-cats').owlCarousel({
				loop:true,
				margin:10,
				rewind:true,
				autoplay:true,
				nav:false,
				dots:false,
				responsive:{
					0:{
						items:7
					},
					600:{
						items:14
					},
					1000:{
						items:5.1
					}
				}
			});
			
			$('.recommended-businesses').owlCarousel({
				loop:true,
				margin:10,
				rewind:true,
				autoplay:true,
				nav:false,
				dots:false,
				responsive:{
					0:{
						items:7
					},
					600:{
						items:14
					},
					1000:{
						items:5.1
					}
				}
			});
		},
			
		destroyCarousel() {
			if ($('.owl-glitz').hasClass('owl-loaded')) {
				$('.owl-glitz').trigger('destroy.owl.carousel').removeClass('owl-loaded');
				$('.owl-glitz').find('.owl-stage-outer').children().unwrap();
			}
			else if ($('.owl-cats').hasClass('owl-loaded')) {
				$('.owl-cats').trigger('destroy.owl.carousel').removeClass('owl-loaded');
				$('.owl-cats').find('.owl-stage-outer').children().unwrap();
			}
		}
			
	};

  
</script>
  
<style>
/* Add global styles here */
@media only screen and ( max-width: 700px){
	header{
		display: none !important;
	}
}
</style>
  